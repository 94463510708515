<template>
    <div>
        <div class='row'>
            <h4 class='col-6 mb-4 page-title'>Платежи (OLD)</h4>
            <div class='col-6 text-right' v-if='stats'>
                <span class='mr-3'>За месяц: {{ stats.month | readableSum }}₽</span>
                <span class='mr-3'>За 30 дней: {{ stats.last_30_days | readableSum }}₽</span>
                <span class='mr-3'>За неделю: {{ stats.week | readableSum }}₽</span>
                <span>Все время: {{ stats.total | readableSum }}₽</span>
            </div>
        </div>
        <div class='leads-list widget'>
            <div class='row mb-3'>
                <div class='input-group col-4'>
                    <input type='search' class='form-control' v-model='searchWord' id='search-input'>
                    <span class='input-group-append'>
              <button type='button' class='btn btn-default' @click='search'>Искать</button>
            </span>
                </div>
                <div class='payment_filter col-4'>
                    <input type='checkbox' @change="toggleFilter('success')" v-model='onlySuccessed' id='onlySuccessed'>
                    <label type='button' for='onlySuccessed'>Только успешные платежи</label>
                </div>
                <!--        <div class="col-6">-->
                <!--          <div class="d-flex align-items-center h-100 justify-content-end">-->
                <!--            <label for="trashed_mark" class="m-0 pr-2">Показать удаленные заявки</label>-->
                <!--            <input id='trashed_mark' type="checkbox" v-model="trashed" :value="true"-->
                <!--                   @change.prevent="handleTrashed">-->
                <!--          </div>-->
                <!--        </div>-->
            </div>
            <div class='table_wrapper'>
                <table class='table table-hover'>
                    <thead class='table_head'>
                    <tr>
                        <th>Имя</th>
                        <th>Телефон</th>
                        <th>Статус</th>
                        <th>Сумма</th>
                        <th>Товар</th>
                        <th>Транзакция</th>
                        <th>Касса</th>
                        <th>Дата изменения</th>
                        <th v-if='isAdmin' class='text-center actions'>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for='(payment, index) in getPayments' :key='index'>
                        <td>{{ payment.name }}</td>
                        <td>{{ payment.phone }}</td>
                        <td>
                            <b-badge :variant='badgeMap[payment.status].css' pill
                                     :title='badgeMap[payment.status].title'>
                                {{ payment.status }}
                            </b-badge>
                        </td>
                        <td>{{ payment.amount }}</td>
                        <td>{{ payment.product_name }}</td>
                        <td>{{ payment.transaction_id }}</td>
                        <td>
                            <span class='provider_img'
                                  v-if="payment.provider_code === 'yookassa' || payment.provider_code === 'yookassa2'"
                                  :title='payment.provider'>
                            <svg viewBox='0 0 266 63' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M57.2192 0C39.6749 0 25.7192 14.1551 25.7192 31.5C25.7192 49.0443 39.8743 63 57.2192 63C74.5642 63 88.7192 48.8449 88.7192 31.5C88.7192 14.1551 74.5642 0 57.2192 0ZM57.2192 43.0633C50.8395 43.0633 45.4566 37.6804 45.4566 31.3006C45.4566 24.9209 50.8395 19.538 57.2192 19.538C63.599 19.538 68.9819 24.9209 68.9819 31.3006C68.7825 37.8797 63.599 43.0633 57.2192 43.0633Z'
                                    fill='url(#paint1_linear)'></path>
                                <path d='M25.519 8.97156V54.826H14.3544L0 8.97156H25.519Z'
                                      fill='url(#paint1_linear)'></path>
                                <defs>
                                    <linearGradient id='paint0_linear' x1='57.2192' y1='0' x2='57.2192' y2='63'
                                                    gradientUnits='userSpaceOnUse'>
                                        <stop stop-color='#0160D1'></stop>
                                        <stop offset='1' stop-color='#00479C'></stop>
                                    </linearGradient>
                                    <linearGradient id='paint1_linear' x1='12.7595' y1='8.97156' x2='12.7595'
                                                    y2='54.826'
                                                    gradientUnits='userSpaceOnUse'>
                                        <stop stop-color='#0160D1'></stop>
                                        <stop offset='1' stop-color='#00479C'></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                            </span>
                            <span v-else-if="payment.provider_code === 'dolyami' || payment.provider_code === 'dolyame'" class='provider_img'
                                  :title='payment.provider'>
                                <img src='../../assets/icons/mnu_dolyame_payment.png' alt=''>
                            </span>
                          <span v-else-if="payment.provider_code === 'paymtech-kz' || payment.provider_code === 'paymtech-ru'" class='provider_img'
                                :title='payment.provider'>
                                <img src='../../assets/icons/paymtech.png' alt=''>
                          </span>

                        </td>
                        <td>{{ payment.updated_at | normalizeDate }}</td>
                        <td v-if='isAdmin'>

                            <a v-if="payment.status === 'успешная оплата' || payment.status === 'в обработке (успех)'"
                               class='btn mr-2 btn-danger'
                               @click.prevent='refundProduct(index)'
                               title='Возврат средств'>
                                <span class='fa fa-history delete'></span>
                            </a>

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class='overflow-auto'>
            <b-pagination class='paginator'
                          v-model='currentPage'
                          :total-rows='getPageMeta.total'
                          :per-page='getPageMeta.per_page'
                          aria-controls='my-table'
            ></b-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RefundModal from '../../components/Modal/RefundModal';
import dayjs from 'dayjs';
import { debounce } from 'debounce';

export default {
    name: 'PaymentList',
    data() {
        return {
            routerParams: { ...this.$route.query },
            currentPage: this.$route.query.page || 1,
            searchWord: null,
            onlySuccessed: false,
            payments: null,
            badgeMap: {
                'в обработке (успех)': {
                    title: 'заявка обработана деньги у клиента списаны, задержка подтверждения',
                    css: 'success',
                },
                'ждет подтвержд': {
                    title: 'ждет подтверждения магазина',
                    css: 'yellow',
                },
                'в ожидании оплаты': {
                    title: 'новая заявка, клиент еще не оплатил',
                    css: 'gray',
                },
                'отказ по заявке': {
                    title: 'отказано банком по какой либо причине или платеж не прошел',
                    css: 'danger',
                },
                'не оплачен': {
                    title: 'не оплачен',
                    css: 'danger',
                },
                'отменен': {
                    title: 'отменен',
                    css: 'danger',
                },
                'успешная оплата': {
                    title: 'успешная оплата',
                    css: 'success',
                },
                'возврат': {
                    title: 'возврат товара',
                    css: 'yellow',
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            getPayments: 'payments/getList',
            getDetails: 'payments/getDetails',
            getPageMeta: 'payments/getPageMeta',
            getRole: 'user/getRole',
            stats: 'payments/getPaymentsStats',
        }),
        isAdmin() {
            return this.getRole === 'admin';
        },
        // getPageMeta(state) {
        //   return state.pageMeta;
        // }
    },
    watch: {
        currentPage(value) {
            this.routerParams['page'] = value;
            this.updateRouter();
            // this.fetchList()
        },
        searchWord(value) {
            this.debounceSearch();
        },
    },
    filters: {
        normalizeDate(date) {
            return dayjs(date).format('DD.MM.YYYY HH:mm');
        },
        readableSum(sum) {
            return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
    },
    methods: {
        ...mapActions({
            loadPayments: 'payments/fetchData',
            loadDetails: 'payments/loadPaymentDetails',
            refundOrder: 'payments/refundItem',
            findData: 'payments/findData',
        }),
        async toggleFilter(type) {
            const filters = {
                ...this.routerParams,
            };
            if (type === 'success') {
                this.onlySuccessed ? filters['success_payment'] = 1 : delete filters['success_payment'];
            }
            this.routerParams = { ...filters };
            await this.updateRouter();
        },
        async updateRouter() {
            await this.$router.push({ name: 'PaymentList', query: this.routerParams });
            await this.fetchList();
        },
        async fetchList() {
            await this.loadPayments(this.$route.query);
        },
        async search() {
            // if (this.searchWord.length > 2) {
            //   await this.findData(this.searchWord);
            // }
            await this.updateRouter();
        },
        debounceSearch: debounce(async function(e) {
            this.routerParams['search'] = this.searchWord;
            await this.updateRouter();
            // console.log('watch');
            // await this.fetchList();
        }, 300),
        notify(type, errMsg) {
            let msg = type === 'error' ? `Не выполнено! ${errMsg}` : `Товар возвращен ждите обновления статуса!`;
            this.$toasted[type](msg, {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
        async refundItem(item, payload) {
            try {
                const result = await this.refundOrder({ item, payload });
                this.notify('success');
                this.$modal.hideAll();
                await this.fetchList();
                return result;
            } catch (e) {
                this.notify('error', e);
            }
        },
        refundProduct(item_index) {
            const item = { ...this.getPayments[item_index] };
            console.log('-----', item);

            this.$modal.show(
                RefundModal,
                {
                    item: item,
                    handler: this.refundItem,
                }, { name: 'RefundModal', height: 'auto' },
            );

        },
    },
    async created() {
        await this.fetchList();
        this.searchWord = this.$route.query.search || null;
        this.onlySuccessed = this.$route.query.success_payment || false;
    },
};
</script>

<style scoped lang='scss'>
.payment_filter {
    display: flex;
    align-items: center;

    label {
        margin: 0 0 0 5px;
    }
}
</style>