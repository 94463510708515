<template>
    <div>
        <h4>Пользователь</h4>
        <div class="widget">
            <b-form class="row g-1" @submit="updateDetails" @reset="back">
                <b-form-group
                    id="input-group-1"
                    label="Логин:"
                    class="col-6"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-1"
                        v-model="form.login"
                        type="text"
                        placeholder="Логин"
                        :state="loginValidate"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback>
                        Логин должен быть больше 3 символов
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    id="input-group-1"
                    label="Имя:"
                    class="col-6"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-1"
                        v-model="form.name"
                        type="text"
                        placeholder="Имя"
                        :state="nameValidation"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback>
                        Поле не может быть пустым
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Email:"
                    class="col-6"
                    label-for="input-email"
                >
                    <b-form-input
                        id="input-email"
                        v-model="form.email"
                        type="text"
                        placeholder="Email"
                        :state="emailValidation"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback>
                        Email не может быть пустым
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    v-if="!isAddPage"
                    id="input-group-1"
                    label="Смена пароля:"
                    class="col-6"
                    label-for="input-pwd"
                >
                    <b-form-input
                        id="input-pwd"
                        v-model="form.password"
                        type="text"
                        placeholder="Новый Пароль"
                        :required="isAddPage"
                    ></b-form-input>
                    <b-form-text id="input-live-help">Оставьте поле пустым если не будет изменений.</b-form-text>
                    <b-form-invalid-feedback>
                        Паполь не может быть меньше 6 символов
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    v-else
                    id="input-group-1"
                    label="Пароль:"
                    class="col-6"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-1"
                        v-model="form.password"
                        type="text"
                        placeholder="Пароль"
                        description="adwadawd"
                        :state="passwordValidator"
                        :required="isAddPage"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                        Паполь не может быть меньше 6 символов
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    id="input-group-1"
                    label="Роль:"
                    class="col-6"
                    label-for="input-1"
                >
                    <b-form-select
                        id="inline-form-custom-select-pref"
                        class=""
                        :options="roles"
                        :value="form.role"
                        :state="roleValidation"
                        v-model="form.role"
                    ></b-form-select>
                    <b-form-invalid-feedback>
                        Необходимо выбрать роль
                    </b-form-invalid-feedback>
                </b-form-group>





                <b-form-group
                    id="input-group-1"
                    label="Тел. номер в wazzup24:"
                    class="col-6"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-1"
                        v-model="form.phone"
                        type="text"
                        placeholder=""
                        :state="phoneValidation"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback>
                        Введите 11-ти значный номер (пример: 79131234567)
                    </b-form-invalid-feedback>
                </b-form-group>





                <b-form-group class="col-12 text-right">
                    <b-button type="submit" class="mr-2" variant="success" :disabled="!isSendAvailable">Сохранить
                    </b-button>
                    <b-button type="reset" variant="primary">Назад</b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "UserDetail",
    data() {
        return {
            roles: [

            ],
            form: {
                id: null,
                login: null,
                name: null,
                email: null,
                role: 'manager',
                role_title: null,
                password: null,
                phone: null,
            }
        }
    },
    computed: {
        ...mapGetters({
            getUsersDetails: 'users/getUsersDetails',
            getAllRoles: 'common/getAllRoles'
        }),
        isAddPage() {
            return this.$route.name === 'UserAdd'
        },
        loginValidate() {
            return this.form.login ? this.form.login.length > 3 : false
        },
        nameValidation() {
            return this.form.name ? this.form.name.length >= 2 : false
        },
        phoneValidation() {
            return this.form.phone ? this.form.phone.length >= 11 && /^\d+$/.test(this.form.phone) : false
        },
        emailValidation() {
            const regExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
            return (this.form.email) ? regExp.test(this.form.email) : false;
        },
        roleValidation() {
            return !(!this.form.role);
        },
        passwordValidator() {
            return this.form.password ? this.form.password.length >= 6 : false
        },
        isSendAvailable() {
            return this.loginValidate
                && this.nameValidation
                && this.phoneValidation
                && this.roleValidation
                && (this.isAddPage ? this.passwordValidator : true)
                && this.emailValidation;
        }
    },
    methods: {
        ...mapActions({
            fetchUserData: 'users/fetchUserData',
            updateUserData: 'users/updateUserData'
        }),
        updateDetails(e) {
            e.preventDefault()
            this.saveUser()
        },
        back() {
            this.$router.back()
        },
        buildFormData() {
            for (const key in this.getUsersDetails) {
                const formKeys = Object.keys(this.form)
                if (formKeys.includes(key)) {
                    this.form[key] = this.getUsersDetails[key]
                }
            }
        },
        async saveUser() {
            try {
                const result = await this.updateUserData({ ...this.form });
                if (result.status === 'success') {
                    this.$toasted.success('Успешно сохранено', {
                        position: 'bottom-right',
                        action: {
                            text: 'Закрыть',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        }
                    });
                    if (this.isAddPage) {
                        this.$router.push({ name: 'UsersPage' });
                    }
                } else {
                    this.$toasted.error(`Не удалось сохранить! ${result.errors[0]}`, {
                        position: 'bottom-right',
                        action: {
                            text: 'Закрыть',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        }
                    });
                }
            } catch (e) {
                console.error(e);
                this.$toasted.error(`Не удалось сохранить! ${e}`, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                });
            }
        }
    },
    async created() {
        await this.fetchUserData(this.$route.params.id);
        this.roles = this.getAllRoles.filter(_role => (_role !== 'teacher' && _role !== 'superadmin'));
        this.buildFormData();
    }
}
</script>

<style lang="scss" scoped>

</style>
