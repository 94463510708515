<template>
  <div class="payment__modal">
    <!--    {{ item }}-->
    <form action="" @submit.prevent="refundConfirm">
      <div class="controls">
        <div class="payment__controls">
          <label>ФИО Покупателя: </label>
          <label class="bold">{{ item.name }}</label>
        </div>
        <div class="payment__controls">
          <label>Позиция</label>
          <input disabled type="text" :value="item.product_name">
        </div>

        <div class="payment__controls">
          <label for="manual" v-if="item.provider !== 'Долями'">Ручной ввод: <input id="manual" type="checkbox"
                                                                                    :min="1" :max="item.quantity"
                                                                                    v-model="manualInput"></label>
        </div>
        <div class="payment__controls" v-if="!manualInput">
          <label>Кол-во позиций*</label>
          <p class="pos__descr">Если товар относится к занятиям то, максимальное значение = количество занятий</p>
          <input required type="number" :min="1" :max="item.quantity" v-model="refundQty">
        </div>
        <div class="payment__controls" v-else>
          <label>Сумма возврата*</label>
          <p class="pos__descr">Сумма возврата не должна превышать оплаченную сумму</p>
          <input required type="number" :min="1" :max="item.amount" v-model="refAmount">
        </div>

        <div class="payment__controls">
          <label>Причина возврата*</label>
          <textarea required v-model="refundNote"></textarea>
        </div>

      </div>

      <div class="payment__controls">
        <label class="bold">Оплаченная сумма: {{ item.amount }}</label>
        <label>Стоимость позиции: {{ item.price_per_unit }}</label>
        <label>Сумма к возврату: {{ amount }}</label>
        <!--          <input disabled type="number" :min="1" :max="item.quantity" v-model="refAmount">-->
      </div>

      <div class="actions">
        <button type="submit" class="action__btn">подтвердить</button>
        <button type="reset" class="action__btn cancel" @click="closeModal">отмена</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "RefundModal",
  props: {
    item: {
      type: Object,
      required: true
    },
    handler: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      refundQty: 1,
      refundNote: null,
      refAmount: this.item.price_per_unit,
      manualInput: false,
    }
  },
  computed: {
    amount() {
      if (!this.manualInput) {
        return this.refundQty * this.item.price_per_unit;
      }
      return this.refAmount;
    }
  },
  methods: {
    async refundConfirm() {
      const message = `Сумма возврата:  ${this.amount}\nКлиент: ${this.item.name}`
      const payload = {
        refund_sum: this.refAmount,
        quantity: this.refundQty,
        note: this.refundNote
      }
      if (window.confirm("Подтвердите действие: \n" + message)) {
        const result = await this.handler(this.item, payload)
      }
    },
    closeModal() {
      this.$modal.hideAll();
    }
  }
}
</script>

<style lang="scss" scoped>
.payment__modal {
  padding: 15px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.action__btn {
  width: 140px;
  display: block;
  margin-right: 5px;
  border: none;
  padding: 5px 0;
  background: #3E86CA;
  color: #fff;
  border-radius: 3px;

  &.cancel {
    background: #FD5F00;
  }
}

.payment__controls {
  display: flex;
  flex-direction: column;
  padding: 5px 0;

  textarea {
    height: 200px;
  }

  input, textarea {
    border: 1px solid #eee;
    padding: 5px 10px;
  }
}

.bold {
  font-weight: bold;
}

.pos__descr {
  font-size: 12px;
  padding: 0;
  margin: 0;
}
</style>